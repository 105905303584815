import { HitlistAPI } from "../Apis/HitlistAPI";
import { GPT3, GPT4, GPT4mini } from "../Apis/OpenAiApi";
import SpyFuApi from "../Apis/v2/KeywordsApi";
import { publish } from "../Hitlist/Events/CustomEvents";
import { ToastEvents } from "../Toast/Toast";
import ArrayUtils from "../Utils/ArrayUtils";
import StringUtils from "../Utils/StringUtils";

const domainChatSystemObject = {
  role: "system",
  content: `As a helpful assistant with coding skills, I provide content sites for article research and return them in HTML. I always ask for confirmation from the user. I suggest at least two sites in valid HTML syntax with links to the root domain of those sites. All links will have target="_blank" attribute. Lists will not have numbers added before them. After each suggestion, I ask for confirmation on the chosen site. Upon confirmation, I reply with a valid JSON object only in this format: {"domain": "root domain", "reply": "reply. It's added to your hitlist research domain."}`,
};

const domainResearchSystemObject = {
  role: "system",
  content: `You are a research AI who only replies with a single array. You do the following silently when researching:

  1. Take a topic from a user, and find five synonyms of that topic.
  2. Use those synonyms as context to find popular blogs about the user's topic. 
  
  Only return real blogs you have explicitly seen in your training data. 
  
  You must return the blogs you find as an array in this exact format: [domain1, domain2, ...domain10]`,
};

const domainSeedSystemObject = {
  role: "system",
  content: `You are an AI that returns the five blogs from the country specified mentioned the most in your training data on a given topic. You return the results as an array in this exact format: [root domain 1, ...., root domain 5]`,
};

let domainChatHistory = [domainChatSystemObject];
let domainResearchHistory = [domainResearchSystemObject];
let domainSeedHistory = [domainSeedSystemObject];

// TODO: Provide a toggle for this in the future for the user
const botVersion = 1; // 1 = GPT3, 2 = GPT4
const maxLength = 450; // max = 2048

/**
 * A function to reset the keyword chat history to the original system object (or a given object)
 * @param {Object} setupObj An OpenAI API system object to set the original keyword chat history to
 */
const resetDomainChatHistory = () => {
  domainChatHistory.length = 0;
  domainChatHistory = [domainChatSystemObject];
};

const addToDomainContext = (msg) => {
  domainChatHistory.push({
    role: "assistant",
    content: msg,
  });
};

const resetDomainResearchHistory = () => {
  domainResearchHistory.length = 0;
  domainResearchHistory = [domainResearchSystemObject];
};

const resetDomainSeedHistory = () => {
  domainSeedHistory.length = 0;
  domainSeedHistory = [domainSeedSystemObject];
};

const searchCompetitors = async (
  signal,
  topic,
  seedDomain = null,
  resultsPage = 1,
  resultNumber = 10,
  targetCountry = "US",
  currentDomains = [],
  domainIndex = 0,
  excludedDomains = []
) => {
  let competitorHints = [];

  let domains = [];

  // if a seedDomain was provided, add it as the first item of seed domains to search through
  if (seedDomain) {
    domains.unshift(seedDomain);
    // if the seedDomain is in the list of currentDomains, then set the page to 1
    if (currentDomains.includes(seedDomain)) {
      resultsPage = 1;
    }
  } else {
    domains = await getSeedDomains(
      signal,
      topic,
      targetCountry,
      currentDomains
    );
  }

  if (!domains) throw new Error("Could not find any data for domain.");

  try {
    // if first run, find a reddit to use
    // if (currentDomains.length === 0) {
    //   // get subreddits for the topic
    //   let subreddits = await getSubReddits(signal, topic, targetCountry);

    //   if (subreddits) {
    //     // add the subreddits to the list of domains in the correct format
    //     subreddits.forEach((subreddit) => {
    //       // strip the protocol from the subreddit
    //       subreddit = StringUtils.stripProtocolFromUrl(subreddit);

    //       competitorHints.push({
    //         key: competitorHints.length,
    //         label: subreddit,
    //         sub: [],
    //       });
    //     });
    //   }
    // }

    let response = await SpyFuApi.getTopSEOCompetitors(
      signal,
      domains[domainIndex],
      resultsPage,
      resultNumber,
      targetCountry
    );

    if (response) {
      console.log(
        "🚀 ~ file: DomainBot.js:108 ~ response.results.sort ~ response.results:",
        response.results
      );

      if (response.resultCount) {
        // if a seedDomain was provided, remove it from the list of competitors as it is already displayed
        if (seedDomain) {
          response.results = response.results.filter((result) => {
            return result.domain !== seedDomain;
          });
        } else {
          // add the seed domain to the list of competitors
          competitorHints.push({
            key: competitorHints.length,
            label: domains[domainIndex],
            sub: [],
          });
        }

        // add the competitors to the list of hints up to the resultNumber - 1 (so we can add the seed domain at the end)
        for (let i = 0; i < resultNumber; i++) {
          if (response.results[i]) {
            competitorHints.push({
              key: competitorHints.length,
              label: response.results[i].domain,
              sub: [],
            });
          }
        }

        let joinedResults = currentDomains.concat(competitorHints);

        // if any excluded domains are passed, remove them from the results
        if (excludedDomains.length > 0) {
          joinedResults = joinedResults.filter(
            (domain) =>
              !excludedDomains.some((excluded) =>
                domain.label.toLowerCase().includes(excluded)
              )
          );
        }

        // remove duplicates
        joinedResults = joinedResults.filter(
          (domain, index, self) =>
            index === self.findIndex((d) => d.label === domain.label)
        );

        // shuffle the order of the results randomly
        joinedResults = ArrayUtils.shuffle(joinedResults);

        return joinedResults;
      } else {
        if (domainIndex + 1 < domains.length) {
          // Call the function again with the next domain in the array
          return await searchCompetitors(
            signal,
            topic,
            seedDomain,
            resultsPage,
            resultNumber,
            targetCountry,
            currentDomains,
            domainIndex + 1
          );
        } else {
          return { status: 500, domains: domains || [] };
        }
      }
    } else {
      // TODO: Make suggestions in case the domain was mistyped
      return { status: 500, domains: domains || [] };
    }
  } catch (err) {
    if (domainIndex + 1 < domains.length) {
      // Call the function again with the next domain in the array
      return await searchCompetitors(
        signal,
        topic,
        seedDomain,
        resultsPage,
        resultNumber,
        targetCountry,
        currentDomains,
        domainIndex + 1
      );
    } else {
      throw new Error(err.message);
    }
  }
};

// const getSubReddits = async (signal, topic) => {
//   if (!signal) return [];
//   if (!topic) return [];

//   let redditChatHistory = [];

//   let systemInstruction = {
//     role: "system",
//     content: `This AI only communicates in Arrays. This AI finds a subReddit for a given topic explicitly seen in it's training data. The AI does not make up subreddits. The AI replies with one safe-for-work active subreddit in an array of this exact format: [Full Reddit sub URL]`,
//   };

//   let userPrompt = {
//     role: "user",
//     content: `${systemInstruction}: 
    
//     ${topic}`,
//   };

//   redditChatHistory.push(systemInstruction);
//   redditChatHistory.push(userPrompt);

//   try {
//     let response = await GPT3.generateChat(signal, redditChatHistory, 0.6, 150); // make it super creative

//     if (response) {
//       if (response.choices.length > 0) {
//         redditChatHistory.push({
//           role: "assistant",
//           content: response.choices[0].message.content,
//         });

//         let subReddits = StringUtils.extractArrayFromString(
//           response.choices[0].message.content
//         );

//         if (Array.isArray(subReddits) && subReddits.length > 0) {
//           return subReddits;
//         }
//       }
//     }
//   } catch (error) {
//     console.log("🚀 ~ file: DomainBot.js:242 ~ getSubReddits ~ error:", error);
//   }

//   return [];
// };

const getSeedDomains = async (
  signal,
  topic,
  targetCountry = "us",
  currDomains = []
) => {
  resetDomainSeedHistory();

  let userPrompt = {
    role: "user",
    content: `${
      domainSeedSystemObject.content
    }: Topic: ${topic}, Country: ${targetCountry}}, Similar to ${Array.toString(
      currDomains
    )}`,
  };

  domainSeedHistory.push(userPrompt);

  try {
    let response = await GPT4mini.generateChat(signal, domainSeedHistory, 0.9, 125);
    console.log("🚀 ~ file: DomainBot.js:217 ~ response:", response);

    if (response) {
      if (response.choices.length > 0) {
        let rawResponse = StringUtils.extractArrayFromString(
          response.choices[0].message.content
        );

        domainSeedHistory.push({
          role: "assistant",
          content: response.choices[0].message.content,
        });

        if (rawResponse !== null) {
          // clean up the domains
          rawResponse.forEach((domain) => {
            domain = domain.replace("www.", "");
            domain = domain.replace("http://", "");
            domain = domain.replace("https://", "");
          });

          return rawResponse;
        } else {
          throw new Error("Could not find a seed domain for that topic.");
        }
      } else {
        throw new Error("Could not find a seed domain for that topic.");
      }
    } else {
      throw new Error(
        "There was an error communicating with the server, please try again."
      );
    }
  } catch (err) {
    if (err.status && err.status === 402) {
      publish(ToastEvents.ERROR, err.message);
    } else {
      console.warn(err.message);
      throw new Error(err.message);
    }
  }
};

// function to use GPT to research domains based on a topic or existing site
const researchDomains = async (
  signal,
  domain,
  topic = null,
  maxDomains = 5,
  more = false,
  reset = false,
  targetCountry = "United States"
) => {
  if (reset) {
    resetDomainResearchHistory();
  }

  let userPrompt = {
    role: "user",
    content: `Return ${Math.round(maxDomains + 3)} ${
      more ? "more" : ""
    } sites about ${topic} that are ${
      more ? "different to what you have returned already and are" : ""
    } read in ${targetCountry}.`,
  };

  domainResearchHistory.push(userPrompt);

  try {
    let response = await GPT4mini.generateChat(
      signal,
      domainResearchHistory,
      0.6,
      250
    );

    if (response) {
      if (response.choices.length > 0) {
        let rawResponse = StringUtils.extractArrayFromString(
          response.choices[0].message.content
        );

        domainResearchHistory.push({
          role: "assistant",
          content: response.choices[0].message.content,
        });

        if (rawResponse !== null) {
          // clean up the domains
          rawResponse.forEach((domain) => {
            domain = domain.replace("www.", "");
            domain = domain.replace("http://", "");
            domain = domain.replace("https://", "");
          });

          // loop through rawResponse and check if each domain is live
          let liveDomains = await Promise.all(
            rawResponse.map(async (domain) => {
              let isLive = false;
              try {
                isLive = await HitlistAPI.checkIfActive(domain);
              } catch (err) {
                console.error(
                  "🚀 ~ file: DomainBot.js:110 ~ rawResponse.map ~ err:",
                  err
                );
                isLive = true;
              }
              return isLive ? domain : null;
            })
          );

          // filter out any null values
          liveDomains = liveDomains.filter((domain) => domain !== null);

          // reduce the number of domains to the maxDomains value
          liveDomains = liveDomains.slice(0, maxDomains);

          // create the structure needed for hints display
          let hints = liveDomains.map((domain) => {
            return {
              key: domain,
              label: domain,
              type: "domain",
            };
          });

          return hints;
        } else {
          throw new Error("Could not find any more domains.");
        }
      } else {
        throw new Error("Could not find any more domains.");
      }
    } else {
      throw new Error(
        "There was an error communicating with the server, please try again."
      );
    }
  } catch (err) {
    if (err.status === 402) {
      publish(ToastEvents.ERROR, err.message);
    } else {
      console.warn(err.message);
      throw new Error(err.message);
    }
  }
};

const domainChat = async (
  signal,
  msg,
  version = botVersion,
  allowance = maxLength
) => {
  if (!signal) throw new Error("No signal provided.");

  // For GPT3, the system message is not used by the model very much, and so it should be included in the prompt as well. For GPT4, it takes the system message into account more, so it should be left out of the prompt
  let userPrompt = {
    role: "user",
    content:
      version === 2
        ? msg
        : domainChatHistory.length > 2
        ? msg
        : `${domainChatSystemObject.content}: ${msg}`,
  };

  domainChatHistory.push(userPrompt);

  try {
    let response =
      version === 2
        ? await GPT4mini.generateChat(signal, domainChatHistory, 0.5, allowance)
        : await GPT3.generateChat(signal, domainChatHistory, 0.5, allowance);

    if (!response) throw new Error("Response doesn't look valid: ", response);
    if (!response.choices.length > 0)
      throw new Error("No response found: ", response);
    if (response.choices[0].finish_reason !== "stop")
      throw new Error("Response didn't finish: ", response);

    let rawResponse = response.choices[0].message.content;

    let endResponse = StringUtils.extractObjectFromString(rawResponse);

    // Clean up the response
    if (endResponse === null) {
      rawResponse = StringUtils.convertNewLines(rawResponse);
      rawResponse = StringUtils.removeQuotes(rawResponse);
      rawResponse = StringUtils.removeBackSlashes(rawResponse);
      rawResponse = StringUtils.removeBackTicks(rawResponse);
    } else {
      endResponse.reply = StringUtils.convertNewLines(endResponse.reply);
      endResponse.reply = StringUtils.removeQuotes(endResponse.reply);
      endResponse.reply = StringUtils.removeBackSlashes(endResponse.reply);
      endResponse.reply = StringUtils.removeBackTicks(endResponse.reply);
    }

    const returnResponse = endResponse !== null ? endResponse : rawResponse;

    domainChatHistory.push({
      role: "assistant",
      content: JSON.stringify(
        endResponse === null ? rawResponse : endResponse.reply
      ),
    });

    return returnResponse;
  } catch (err) {
    if (err.status === 402) {
      publish(ToastEvents.ERROR, err.message);
    } else {
      console.warn(err.message);
    }
  }
};

export {
  domainChat,
  researchDomains,
  searchCompetitors,
  addToDomainContext,
  resetDomainChatHistory,
  resetDomainResearchHistory,
};

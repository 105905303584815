import axios from "axios";
import axiosRetry from "axios-retry";
import { CustomEvents, publish } from "../../Hitlist/Events/CustomEvents";
import { STORAGE_KEYS, getValue } from "../../Storage";
import { throwError } from "../../ErrorHandler/ErrorHandler";
import axiosThrottle from "axios-request-throttle";
import Userfront from "@userfront/core";

const minCredit = 1;

axiosThrottle.use(axios, { requestsPerSecond: 25 });

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  baseURL: process.env.REACT_APP_SERVER_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = Userfront.tokens.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const retryCondition = (error) => {
  console.error("🚀 ~ file: OpenAiApi.js:34 ~ error:", error);
  return (
    axiosRetry.isNetworkError(error) ||
    axiosRetry.isRetryableError(error) ||
    error.code === "ECONNABORTED" ||
    error.type === "server_error" ||
    error.response.status === 429 ||
    error.response.status === 500
  ); // too many requests
};

axiosRetry(api, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: retryCondition,
});

const getCredits = async () => {
  // console.log("🚀 ~ file: OpenAiApi.js:38 ~ getCredits ~ getValue(STORAGE_KEYS.CREDITS):", getValue(STORAGE_KEYS.CREDITS))
  return getValue(STORAGE_KEYS.CREDITS) || 0;
};

export const GPT4mini = {
  availableEngines: {
    STANDARD: "gpt-4o-mini",
    LARGE: "gpt-4o-mini",
  },
  chatEngine: process.env.REACT_APP_GPT4MINI_VERSION,
  maxTokens: 150,
  temperature: 0.7,
  presencePenalty: 0.6,
  frequencyPenalty: 0,
  topP: 1,
  stop: "",
  logTokens: (response) => {
    console.log("🚀 ~ file: GPT4:126 ~ response:", response);

    const tokenFactor =
      GPT4mini.maxTokens > 8000
        ? parseInt(process.env.REACT_APP_GPT4_32K_MULTIPLE)
        : parseInt(process.env.REACT_APP_GPT4_8K_MULTIPLE); // how much more expensive than the baseline of GPT3.5

    if (!response) return;
    if (!response.data) return;
    if (!response.data.usage) return;

    const tokens = {
      prompt_tokens: response.data.usage.prompt_tokens,
      completion_tokens: response.data.usage.completion_tokens,
      total_tokens:
        (response.data.usage.prompt_tokens +
          response.data.usage.completion_tokens) *
        tokenFactor,
    };

    publish(CustomEvents.TOKENS_USED, {
      date: new Date(),
      model: response.data.model,
      tokens: tokens,
    });
  },
  /**
   * Function to generate a chat response using the OpenAI API and ChatGPT3
   * @param {Array} messages An array of chatGPT message objects. Follows the format of:
   * [
      {“role”: “system”, “content”: “You are a helpful assistant that translates English to French.”},
      {“role”: “user”, “content”: ‘Translate the following English text to French: “{text}”’}
    ]
   * @param {Number} temp How creative to get with responses
   * @param {Int} maxLength The maximum number of tokens to generate
   * @returns {Object} The response from the OpenAI API as an Object. Responses are contained in the choices array.
   */
  generateChat: async (
    signal,
    messages,
    temp = GPT4mini.temperature,
    maxLength = GPT4mini.maxTokens,
    functions = null,
    presencePenalty = GPT4mini.presencePenalty,
    frequencyPenalty = GPT4mini.frequencyPenalty,
    model = GPT4mini.chatEngine,
    response_format = {"type": "json_object"}
  ) => {
    if (!signal) throwError("No signal provided", 400);

    try {
      const availableCredits = await getCredits();

      if (availableCredits >= minCredit) {
        let params = {
          model: model,
          messages: messages,
          temperature: temp,
          max_tokens: maxLength,
          presence_penalty: presencePenalty,
          frequency_penalty: frequencyPenalty,
          top_p: GPT4mini.topP,
        };

        if (functions) params.functions = functions;

        try {
          const response = await api.post("/chat/completions", params, {
            signal: signal,
          });

          GPT4mini.logTokens(response);

          return response.data;
        } catch (error) {
          throwError(error, 500);
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (error) {}
  },
   /**
   * Function to generate a chat response using the OpenAI API and ChatGPT3
   * @param {Array} messages An array of chatGPT message objects. Follows the format of:
   * [
      {“role”: “system”, “content”: “You are a helpful assistant that translates English to French.”},
      {“role”: “user”, “content”: ‘Translate the following English text to French: “{text}”’}
    ]
   * @param {Number} temp How creative to get with responses
   * @param {Int} maxLength The maximum number of tokens to generate
   * @returns {Object} The response from the OpenAI API as an Object. Responses are contained in the choices array.
   */
    generateChat: async (
      signal,
      messages,
      temp = GPT4mini.temperature,
      maxLength = GPT4mini.maxTokens,
      functions = null,
      presencePenalty = GPT4mini.presencePenalty,
      frequencyPenalty = GPT4mini.frequencyPenalty,
      model = GPT4mini.chatEngine,
      topic
    ) => {
      if (!signal) throwError("No signal provided", 400);
  
      try {
        const availableCredits = await getCredits();
  
        if (availableCredits >= minCredit) {
          let params = {
            messages: messages
          };
  
          //if (functions) params.functions = functions;
          console.log('topic', topic);
  
          try {
            const response = await api.post("/articles/research", params, {
              signal: signal,
            });
  
            GPT4mini.logTokens(response);
  
            return response.data;
          } catch (error) {
            throwError(error.response.data.error.message, error.response.status);
          }
        } else {
          throwError(
            `Insufficient credits. Your current balance is ${availableCredits}`,
            402
          );
        }
      } catch (error) {}
    },


    streamChat: async (
      signal,
      messages,
      onData,
      temp = GPT4mini.temperature,
      maxLength = GPT4mini.maxTokens,
      presencePenalty = GPT4mini.presencePenalty,
      frequencyPenalty = GPT4mini.frequencyPenalty,
      model = GPT4mini.chatEngine,
    ) => {
      const token = Userfront.tokens.accessToken || null;

      let params = {
        model: model,
        messages: messages,
        temperature: temp,
        max_tokens: maxLength,
        presence_penalty: presencePenalty,
        frequency_penalty: frequencyPenalty,
        top_p: GPT4mini.topP,
        stream: true,
        response_format: {"type": "json_object"}
      };

      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}articles/generate`, {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
           },
           body: JSON.stringify({messages: messages}), // @todo find a way not to pass bulky message request
      });
  
      const reader = response.body?.getReader();
      if (!reader) {
          console.error("Error: No response body");
          return;
      }else{
        console.log("Reader created");
      }

        if (!response.body) {
          console.error("No response body");
          return;
      }
        
    if (!reader) {
        console.error("Error: Failed to read data from response");
        return;
    }

    let deltaText = "";
    let fullResponse = "";
    const textDecoder = new TextDecoder("utf-8");

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        console.log("Received chunk:", { done, value });

        const chunk = textDecoder.decode(value);
        for (const line of chunk.split("\n")) {
            const trimmedLine = line.trim();
            if (!trimmedLine || !trimmedLine.startsWith("data: ")) continue;

            try {
                const json = trimmedLine.replace("data: ", "");
                const obj = JSON.parse(json);
                let content = obj.choices[0]?.delta?.content || "";

                if (obj.choices[0]?.finish_reason === "stop") {
                    console.log("STOPPING:", fullResponse);
                    return;
                }

                content = content.replace(/([{,])(\s*)([a-zA-Z0-9_]+)\s*:/g, '$1"$3":');
                deltaText += content;
                fullResponse += content;

                let startMatch = deltaText.match(/\{\s*"data"\s*:\s*\[/);
                let boundary = startMatch ? startMatch.index + startMatch[0].length : -1;
                let endMatch = deltaText.match(/,\s*\{\s*"id"\s*:/);
                let endBoundary = endMatch ? endMatch.index : -1;

                if (boundary > -1 && endBoundary > -1) {
                    const input = deltaText.slice(boundary, endBoundary);
                    deltaText = deltaText.replace(input + ",", "");

                    try {
                        const parsedData = JSON.parse(input);
                        onData(parsedData);
                    } catch (e) {
                        console.error("Error parsing JSON:", e);
                    }
                }
            } catch (e) {
                console.error("Error processing chunk:", e);
            }
        }
    }
          
  
            console.log("ALL DONE");
  
            return { complete: true, data: [] };
      } catch (e) {
        console.log("🚀 ~ file: SpyFuApi.js:513 ~ e:", e);
      }

    },
};

import { createContext } from "react";

const config = {
  REGISTER_URL: "https://hitlist.ai/go/hitlist/hitlistai-choose-plan/",
  settings: {
    TARGET_COUNTRY_OPTIONS: [
      { value: "US", label: "United States" },
      { value: "GB", label: "United Kingdom" },
      { value: "AU", label: "Australia" },
      { value: "BR", label: "Brazil" },
      { value: "CA", label: "Canada" },
      { value: "DE", label: "Germany" },
      { value: "JP", label: "Japan" },
      { value: "ES", label: "Spain" },
      { value: "FR", label: "France" },
      { value: "IE", label: "Ireland" },
      { value: "IN", label: "India" },
      { value: "IT", label: "Italy" },
      { value: "MX", label: "Mexico" },
      { value: "NL", label: "Netherlands" },
      { value: "SG", label: "Singapore" },
    ],
    TARGET_LANGUAGES: [
      { value: "en", label: "English" },
      { value: "es", label: "Spanish" },
      { value: "fr", label: "French" },
      { value: "de", label: "German" },
      { value: "it", label: "Italian" },
      { value: "ja", label: "Japanese" },
      { value: "ko", label: "Korean" },
      { value: "pl", label: "Polish" },
      { value: "pt", label: "Portuguese" },
      { value: "ru", label: "Russian" },
      { value: "nl", label: "Dutch" },
      { value: "tr", label: "Turkish" },
    ],
    SORT_BY_OPTIONS: [
      { value: "opportunity", label: "Opportunity Factor" },
      { value: "volume", label: "Search Volume" },
      { value: "starred", label: "Starred" },
      { value: "title", label: "Title" },
      { value: "type", label: "Type" },
      { value: "uuid", label: "Generated Order" },
    ],
    SORT_ORDER_OPTIONS: [
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ],
    HEADLINE_STYLES: [
      {
        value: "SEO headline",
        label: "SEO Focussed",
        description: "SEO-based headline using a mixture of styles",
      },
      {
        value: "Before and After headline",
        label: "Before and After",
        description: "Headline comparing before and after",
      },
      {
        value: "Common mistakes headline",
        label: "Common Mistakes",
        description: "Common mistakes headline",
      },
      {
        value: "Curiosity headline",
        label: "Curiosity",
        description: "Curiosity headline",
      },
      {
        value: "How-to headline",
        label: "How-to's",
        description: "How-to headline",
      },
      {
        value: "Listicle headline",
        label: "Listicles",
        description: "Listicle headline listing between 5 and 27 items",
      },
      {
        value: "Headline reviewing a single product",
        label: "Product Reviews",
        description: "Headline reviewing a single product",
      },
      {
        value: "Podcast episode idea",
        label: "Podcast Episode",
        description: "An idea for a podcast episode",
      },
      {
        value: "Question headline",
        label: "Questions",
        description: "Question headline",
      },
      {
        value: "Reason why headline",
        label: "Reasons Why",
        description: "Reason why headline",
      },
      {
        value: "Recipe headline",
        label: "Recipes",
        description: "Recipe style headline",
      },
      {
        value: "Secrets or mystery headline",
        label: "Secrets",
        description: "Secrets or mystery headline",
      },
      {
        value: "Testimonial or review headline",
        label: "Testimonials",
        description: "Testimonial or review headline",
      },
      {
        value: "Why you should headline",
        label: "Why You Should",
        description: "Why you should headline",
      },
      {
        value: "Wordplay headline",
        label: "Wordplay",
        description: "A headline that is a play on words",
      },
    ],
  },
  chat: {
    talkingSpeed: 4,
    replySpeed: 450,
    stopPhrases: [
      "turn off the mic",
      "turn off the mike",
      "turn off the microphone",
      "stop listening",
    ],
  },
  hitlist: {
    generate: {
      defaultDomainResults: 10,
      defaultDomainsGenerated: 5,
      defaultKeywordResults: 10,
      defaultKeywordsGenerated: 5,
      defaultSimilarFilter: 0.75,
      headlineStyle: "SEO headline",
      maxDomainResults: 27,
      maxDomainsGenerated: 10,
      maxKeywordResults: 27,
      maxKeywordsGenerated: 10,
      minCredits: 0,
      sortBy: "uuid",
      sortOrder: "asc",
      targetCountry: "US",
      targetLanguage: "en",
      titleRewrites: 2,
    },
    excludedDomains: [
      "ebay.com",
      "amazon.co",
      "myshopify.com",
      "wikipedia.org",
      "google.com",
      "linkedin.com",
      "facebook.com",
      "twitter.com",
      "tiktok.com",
      "instagram.com",
      ".org",
      ".gov",
    ],
    excludedWords: [
      'Unleash',
      'Unlock',
      'Elevate'
    ],
    constants: {
      STEP_NICHE: "niche",
      STEP_KEYWORDS: "keywords",
      STEP_DOMAIN: "domains",
      GUIDE_CHAT_MODE: "chatMode",
    },
    statuses: {
      PLACEHOLDER: "Placeholder",
      STATUS_SELECTIONS: [
        { value: "", label: "Select a status..." },
        { value: "Save to Backlog", label: "Save to Backlog" },
        { value: "To Write", label: "To Write" },
        { value: "Editing", label: "Editing" },
        { value: "Idea Abandoned", label: "Idea Abandoned" },
      ],
      STATUS_WRITING: [
        { value: "Writing", label: "Writing" },
        { value: "Editing", label: "Editing" },
        { value: "Ready for Review", label: "Ready for Review" },
        { value: "Images Needed", label: "Images Needed" },
      ],
      STATUS_PUBLISHING: ["Published", "SEO", "Social"],
    },
    tabs: [
      {
        title: "Research",
        count: 0,
        tableData: [],
      },
      {
        title: "Final Hitlist",
        count: 0,
        tableData: [],
      },
    ],
    members: {
      ADMINS: [
        { value: "", label: "Assign to..." },
        { value: "Luisa", label: "Luisa" },
        { value: "Adam", label: "Adam" },
        { value: "Raels", label: "Raels" },
        { value: "Tracey", label: "Tracey" },
        { value: "Archie", label: "Archie" },
      ],
    },
  },
};

export const AppConfigContext = createContext(config);

import { memo } from "react";
import { ThemeProvider, Grid, Box } from "@mui/material";
import LoginOptions from "./LoginOptions/LoginOptions";
import LoginImage from "./LoginImage/LoginImage";
import Version from "../Header/Version/Version";
import CircularProgress from '@mui/material/CircularProgress';

const Login = (props) => {
  const { theme, loggedIn, loggingIn, initialized } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const uuid = urlParams.get("uuid") || null;
  const token = urlParams.get("token") || null;

  if(uuid){
    return  (
    <Box display="flex" 
    justifyContent="center" 
    alignItems="center" 
    height="100vh"><CircularProgress /></Box>
    )
  }

  return (
    <>
    { !uuid &&  (
      <ThemeProvider theme={theme}>
        <Box position="absolute" sx={{ right: 30, top: 20 }}>
          <Version color="#dfdfdf" hideDate={true} />
        </Box>
        <Grid
          id="login-container"
          container
          spacing={0}
          columns={16}
          backgroundColor="background.paper"
        >
          <Grid item className="login-image" xs={9}>
            <LoginImage />
          </Grid>
          <Grid
            item
            className="login-form"
            xs={7}
            sx={{
              height: "100vh",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoginOptions
              loggedIn={loggedIn}
              loggingIn={loggingIn}
              initialized={initialized}
            />
          </Grid>
        </Grid>
      </ThemeProvider>
      )}
    </>
  );
};

export default memo(Login);

const APP_CONSTANTS = {
  ARTICLE_TYPE_SNIPPET: "type_snippet",
  ARTICLE_TYPE_ENHANCED: "type_enhanced",
  ARTICLE_TYPE_QUESTION: "type_question",
  ARTICLE_TYPE_PRODUCT_REVIEW: "type_product_review",
  ARTICLE_TYPE_AI_IDEA: "type_ai_idea",
  ARTICLE_TYPE_COMPETITOR_ARTICLE: "type_competitor_article",
  ARTICLE_TYPE_RELATED_ARTICLE: "type_related_article",
  ARTICLE_TYPE_VIDEO: "type_video",
  ARTICLE_TYPE_REWRITE: "type_rewrite",
  ARTICLE_TYPE_REDDIT: "type_reddit",
  ARTICLE_TYPE_SOCIAL: "type_social",
  LOGIN_TYPE_EMAIL: "email",
  LOGIN_TYPE_GOOGLE: "google",
  LOGIN_TYPE_FACEBOOK: "facebook",
  LOGIN_TYPE_LINKEDIN: "linkedin",
  LOADING_TYPE_VOLUME: "volume",
  LOADING_TYPE_KEYWORDS: "keywords",
  LOADING_TYPE_TITLE: "title",
  LOADING_TYPE_ENHANCE: "enhance",
  HEADLINE_TYPE_SEO: "SEO headline",
  HEADLINE_TYPES: [
    {
      value: "SEO headline",
      label: "SEO Focussed",
      description: "SEO-based headline using a mixture of styles",
    },
    {
      value: "Before and After headline",
      label: "Before and After",
      description: "Headline comparing before and after",
    },
    {
      value: "Common mistakes headline",
      label: "Common Mistakes",
      description: "Common mistakes headline",
    },
    {
      value: "Curiosity headline",
      label: "Curiosity",
      description: "Curiosity headline",
    },
    {
      value: "How-to headline",
      label: "How-to's",
      description: "How-to headline",
    },
    {
      value: "Listicle headline",
      label: "Listicles",
      description: "Listicle headline listing between 5 and 15 items",
    },
    {
      value: "Podcast episode idea",
      label: "Podcast Episode",
      description: "An idea for a podcast episode",
    },
    {
      value: "Headline reviewing a single product",
      label: "Product Reviews",
      description: "Headline reviewing a single product",
    },
    {
      value: "Question headline",
      label: "Questions",
      description: "Question headline",
    },
    {
      value: "Reason why headline",
      label: "Reasons Why",
      description: "Reason why headline",
    },
    {
      value: "Recipe headline",
      label: "Recipes",
      description: "Recipe style headline",
    },
    {
      value: "Secrets or mystery headline",
      label: "Secrets",
      description: "Secrets or mystery headline",
    },
    {
      value: "Testimonial or review headline",
      label: "Testimonials",
      description: "Testimonial or review headline",
    },
    {
      value: "Why you should headline",
      label: "Why You Should",
      description: "Why you should headline",
    },
    {
      value: "Wordplay headline",
      label: "Wordplay",
      description: "A headline that is a play on words",
    },
  ],
};

export { APP_CONSTANTS };

import axios from "axios";
import axiosRetry from "axios-retry";
import { CustomEvents, publish } from "../../Hitlist/Events/CustomEvents";
import { STORAGE_KEYS, getValue } from "../../Storage";
import { throwError } from "../../ErrorHandler/ErrorHandler";
import Userfront from "@userfront/core";
import { sort } from "semver";

const apiKey = process.env.REACT_APP_SPYFU_KEY;
const minCredit = 1;

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  baseURL: process.env.REACT_APP_SERVER_URL,
});


const retryCondition = (error) => {
  console.error("🚀 ~ file: OpenAiApi.js:34 ~ error:", error);
  return (
    axiosRetry.isNetworkError(error) ||
    axiosRetry.isRetryableError(error) ||
    error.code === "ECONNABORTED" ||
    error.response.status === 429 ||
    error.response.status === 500
  );
};

axiosRetry(api, {
  retries: 5,
  retryCondition: retryCondition,
  shouldResetTimeout: true,
});

const CredentialsApi = {

  validatePasswordResetToken: async (email,token) => {
    try {
    return await api.post(`/v2/credentials/${email}/reset?password_reset_key=${token}`);
    }catch(e){
      console.log("🚀 ~ file: CredentialsApi.js:41 ~ e", e);
      throw e;
    }
  }
}

export default CredentialsApi;
import SimilarityApi from "../Apis/SimilarityApi";
import { GPT4mini } from "../Apis/v2/ArticleApi";
import { GPT3 } from "../Apis/OpenAiApi";
import { APP_CONSTANTS } from "../Hitlist/Constants";
import { publish } from "../Hitlist/Events/CustomEvents";
import { ToastEvents } from "../Toast/Toast";
import StringUtils from "../Utils/StringUtils";
import SpyFuAPI from "../Apis/v2/KeywordsApi";

let chatHistory = [];

const HISTORY_LENGTH = 4;

const resetIdeaHistory = (systemObject) => {
  chatHistory = [systemObject];
};

/**
 * Public function to get keyword ideas from the API
 * @param {AbortController} signal A signal to cancel the request
 * @param {String} headline The headline to use for the idea
 * @param {String} topic The topic to use for the idea
 * @param {String} allTopics All other topics already used to exclude from the idea generation
 * @param {Function} onData A callback function to call when data is received from the API stream
 * @param {Int} resultNumber The number of results to return
 * @param {String} headlineStyle The style of headline to use
 * @param {String} targetCountry The country to target
 * @param {String} targetLanguage The language to target
 * @param {Number} uniqueThreshold The threshold to use for the uniqueness of the headline
 * @param {Boolean} reset Whether to reset the chat history
 * @returns {Object} An object containing the idea data
 * @public
 */
const getKeywordIdeas = async (
  signal,
  headline,
  topic,
  allTopics,
  onData,
  resultNumber = 25,
  headlineStyle = "SEO headline",
  targetCountry = targetCountry,
  targetLanguage = "en",
  uniqueThreshold = 0.75,
  reset = false,
  excludedWords
) => {
  const languageName = StringUtils.getLanguageCodeName(targetLanguage);
  const countryName = StringUtils.getCountryCodeName(targetCountry);

  console.log('target country', targetCountry);

  // get the description of the headlineStyle
  const headlineStyleDescription =
    APP_CONSTANTS.HEADLINE_TYPES.find((style) => style.value === headlineStyle)
      .description || headlineStyle;

  // remove topic from allTopics
  allTopics = allTopics.filter((t) => t !== topic);

  const systemObject = {
    role: "system",
    content: `As an expert content researcher specializing in the provided topic, conduct a comprehensive research plan targeting the specified country. Provide the final results in this specific JSON format: {"data":[{"id":index,"title":"headline","keywords":[{"label":"keyword1","volume": 0},...],"countryCode":"target country code"},...]}.`,
  };

  if (reset || chatHistory.length === 0) {
    resetIdeaHistory(systemObject);

    // add the first user prompt to the chatHistory to encourage the AI to return the correct results
    chatHistory.push({
      role: "user",
      content:
        `\n[Research Data]\nPlease use the following research data to inform your content plan:\n\n\nAquarium supplies can be used for a variety of purposes, such as adding color and life to an aquarium, providing food and shelter for fish, and creating a natural environment.\n\n[Country]\nThe country you are targeting is United States.\n\n[Language]\nAll headlines and keywords are to be written in English.\n\n[Topic]\nThe content plan topic is Aquarium supplies in the context of the broader niche of Aquariums.\n\n[Result Number]\nYou will return 26 new article ideas written as SEO-based headline using a mixture of styles.\n\n[Headline Style]\nEach new article headline will be written as a SEO-based headline using a mixture of styles in English.\n\n[SEO Keywords]\nFor each article idea written as a SEO-based headline using a mixture of styles, provide 5 related SEO keywords in English related to the new article idea and Aquarium supplies.\n\n[Output Format]\nReturn the results in this exact JSON format:\n{"data":[{"id":index,"title":"headline","keywords":[{"label":"keyword1","volume": 0},...],"countryCode":"target country code"},...]}\n\n[Exemptions] Do not use the words "${excludedWords.join(", ")}" on the headline`,
    });

    chatHistory.push({
      role: "assistant",
      content: JSON.stringify({
        "data": [
        {
            "id": 1,
            "title": "Top Aquarium Supplies To Add Color And Life To Your Tank",
            "keywords": [
                {
                    "label": "aquarium supplies",
                    "volume": 0
                },
                {
                    "label": "tank color",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 2,
            "title": "Top 10 Aquarium Supplies To Help Mimic The Habitat Of Fish",
            "keywords": [
                {
                    "label": "fish habitat",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 3,
            "title": "How to Teach Children About The Environment And Marine Life",
            "keywords": [
                {
                    "label": "aquarium education",
                    "volume": 0
                },
                {
                    "label": "children aquarium",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 4,
            "title": "Aquarium Supplies For Beginners: A Guide To Getting Started With Your First Tank",
            "keywords": [
                {
                    "label": "beginner aquarium guide",
                    "volume": 0
                },
                {
                    "label": "essential aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 5,
            "title": "Best Aquarium Supplies For Creating A Natural Environment",
            "keywords": [
                {
                    "label": "natural aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 6,
            "title": "Top 5 Aquarium Supplies For Providing Food And Shelter For Fish",
            "keywords": [
                {
                    "label": "fish food",
                    "volume": 0
                },
                {
                    "label": "fish shelter",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 7,
            "title": "How To Choose The Right Aquarium Supplies For Your Tank",
            "keywords": [
                {
                    "label": "choosing aquarium supplies",
                    "volume": 0
                },
                {
                    "label": "right aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 8,
            "title": "Aquarium Supplies For Advanced Hobbyists: Taking Your Tank To The Next Level",
            "keywords": [
                {
                    "label": "advanced aquarium supplies",
                    "volume": 0
                },
                {
                    "label": "hobbyist aquarium",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 9,
            "title": "Top Aquarium Supplies For Saltwater Tanks",
            "keywords": [
                {
                    "label": "saltwater aquarium supplies",
                    "volume": 0
                },
                {
                    "label": "saltwater tank",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 10,
            "title": "How To Maintain Your Aquarium With The Right Supplies",
            "keywords": [
                {
                    "label": "aquarium maintenance",
                    "volume": 0
                },
                {
                    "label": "right aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 11,
            "title": "Top Aquarium Supplies For Freshwater Tanks",
            "keywords": [
                {
                    "label": "freshwater aquarium supplies",
                    "volume": 0
                },
                {
                    "label": "freshwater tank",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 12,
            "title": "How To Create A Themed Aquarium With The Right Supplies",
            "keywords": [
                {
                    "label": "themed aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 13,
            "title": "Top Aquarium Supplies For Plant Lovers",
            "keywords": [
                {
                    "label": "aquarium plants",
                    "volume": 0
                },
                {
                    "label": "plant aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 14,
            "title": "How To Set Up A Low-Maintenance Aquarium With The Right Supplies",
            "keywords": [
                {
                    "label": "low-maintenance aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 15,
            "title": "Top Aquarium Supplies For Creating A Zen Environment",
            "keywords": [
                {
                    "label": "zen aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 16,
            "title": "How To Choose Eco-Friendly Aquarium Supplies",
            "keywords": [
                {
                    "label": "eco-friendly aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 17,
            "title": "Top Aquarium Supplies For Creating A Vibrant Tank",
            "keywords": [
                {
                    "label": "vibrant aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 18,
            "title": "How To Create A Biotope Aquarium With The Right Supplies",
            "keywords": [
                {
                    "label": "biotope aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 19,
            "title": "Top Aquarium Supplies For Creating A Minimalist Tank",
            "keywords": [
                {
                    "label": "minimalist aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 20,
            "title": "How To Create A High-Tech Aquarium With The Right Supplies",
            "keywords": [
                {
                    "label": "high-tech aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 21,
            "title": "Top Aquarium Supplies For Creating A Low-Light Tank",
            "keywords": [
                {
                    "label": "low-light aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 22,
            "title": "How To Create A Breeding Tank With The Right Supplies",
            "keywords": [
                {
                    "label": "breeding aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 23,
            "title": "Top Aquarium Supplies For Creating A Community Tank",
            "keywords": [
                {
                    "label": "community aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 24,
            "title": "How To Create A Quarantine Tank With The Right Supplies",
            "keywords": [
                {
                    "label": "quarantine aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        },
        {
            "id": 25,
            "title": "Top Aquarium Supplies For Creating A Nano Tank",
            "keywords": [
                {
                    "label": "nano aquarium",
                    "volume": 0
                },
                {
                    "label": "aquarium supplies",
                    "volume": 0
                }
            ],
            "countryCode": targetCountry
        }
    ],
      }),
    });
  } else {
    // if chatHistory is getting too long, remove the second and third element from the array to keep it short
    if (chatHistory.length > HISTORY_LENGTH) {
      chatHistory.splice(1, 2);
    }
  }

  // const returnResultSchema = [
  //   {
  //     name: "process_ideas",
  //     description: `Process an array of idea objects`,
  //     parameters: {
  //       type: "object",
  //       properties: {
  //         data: {
  //           type: "array",
  //           description: `An array of ${resultNumber} objects`,
  //           items: {
  //             type: "object",
  //             properties: {
  //               id: {
  //                 type: "integer",
  //                 description: "The index of the idea",
  //               },
  //               title: {
  //                 type: "string",
  //                 description: `The headline`,
  //               },
  //               keywords: {
  //                 type: "array",
  //                 description: `An array of short-tail SEO keywords`,
  //                 items: {
  //                   type: "object",
  //                   description: `A short-tail SEO keyword object`,
  //                   properties: {
  //                     label: {
  //                       type: "string",
  //                       description: `A short-tail SEO keyword`,
  //                     },
  //                     volume: {
  //                       type: "integer",
  //                       description: "The volume of searches for the keyword",
  //                     },
  //                   },
  //                 },
  //               },
  //               countryCode: {
  //                 type: "string",
  //                 description: "The targeted country code",
  //               },
  //             },
  //             required: ["id", "title", "keywords", "countryCode"],
  //           },
  //         },
  //       },
  //     },
  //   },
  // ];

  try {
    let researchData = await _doResearch(
      signal,
      headline,
      targetCountry,
      targetLanguage
    );

    const userPrompt = {
      role: "user",
      content: `
    [Research Data]
    Please use the following research data to inform your content plan:
    ${researchData.toString()}
    
    [Country]
    The country you are targeting is ${countryName}.

    [Language]
    All headlines and keywords are to be written in ${languageName}.
    
    [Topic]
    The content plan topic is ${headline} and it must relate to the parent niche of ${topic}.
    
    [Result Number]
    ${resultNumber} new article ideas written as ${headlineStyleDescription}s are required.
    
    [Headline Style]
    Each new article of the ${resultNumber} headlines will be written as a ${headlineStyleDescription} in ${languageName}.
    
    [SEO Keywords]
    For each article idea written as a ${headlineStyleDescription}, provide 5 related short-tail, two-word, SEO keywords in ${languageName} related to the new article idea and ${headline}.

    [Exemptions]
    Do not use these words ${excludedWords.join(", ")} on the headline
    
    [Output Format]
    Return the results in this exact JSON format. The JSON should have no spaces between brackets, commas and colons:
    {"data":[{"id":1, "title":"headline as a ${headlineStyleDescription}1","keywords":[{"label":keyword1,"volume":0},...],"countryCode":"${targetCountry}"},...,{"id":"${resultNumber}","title":"headline as a ${headlineStyleDescription}${resultNumber}","keywords":[{"label":"keyword1","volume":0},...],"countryCode":"${targetCountry}"}]`,
    };

    chatHistory.push(userPrompt);

    // let response = await GPT3.generateChat(
    //   signal,
    //   chatHistory,
    //   0.7,
    //   2048,
    //   returnResultSchema,
    //   0.6,
    //   0,
    //   GPT3.availableEngines.LARGE
    // );

    const response = await GPT4mini.streamChat(
      signal,
      chatHistory, //topic,
      onData,
      0.7,
      2048,
      0.6,
      0,
      GPT4mini.availableEngines.LARGE,
    );

    if (response) {
      console.log("🚀 ~ file: IdeasBot.js:224 ~ response:", response);
      return response;
    }
  } catch (err) {
    if (err.status === 402) publish(ToastEvents.ERROR, err.message);
    return { complete: true, data: [], error: err.message };
  }
};

const _doResearch = async (
  signal,
  headline,
  targetCountry = targetCountry,
  targetLanguage = "en"
) => {
  let researchData = [];

  try {
    let prompt = `The following is a conversation with an AI researcher who provides a writing brief for a content planner on a topic. The AI is helpful, creative, clever, and very knowledgeable.\n\nHuman: Summarize in two short paragraphs what types, and uses for ${headline}.`;
    let research = await GPT4mini.generateText(
      signal,
      prompt,
      0.1,
      800,
      GPT4mini.availableEngines.TEXT
    );

    // loop through organic results of research and add them to the a new researchData array
    if (research) {
      researchData.push(
        StringUtils.cleanGPT3Response(research.choices[0].text)
      );
      return researchData;
    }
  } catch (e) {
    console.log("🚀 ~ file: IdeasBot.js ~ e", e);
    return [];
  }
};

const _removeSimilarResults = async (resultsArray, threshold = 0.75) => {
  if (!resultsArray.length) return resultsArray;
  if (threshold === 1) return resultsArray; // if the threshold is 1, then return the resultsArray as nothing will be similar

  let titlesOnlyArray = resultsArray.map((result) => result.title);
  let controller = new AbortController();

  let i = 0;

  while (i < titlesOnlyArray.length) {
    let title = titlesOnlyArray[i];

    const scores = await SimilarityApi.getSimilarityScores(
      controller.signal,
      title,
      titlesOnlyArray
    );

    if (scores && scores.similarities) {
      // loop through the scores and remove any results that are too similar from the titlesOnlyArray
      scores.similarities.forEach((score, index) => {
        if (score >= threshold && score < 1) {
          titlesOnlyArray.splice(index, 1);
        }
      });
    }

    i++;
  }

  // loop through the resultsArray and remove any results that are not contained in the now filtered for similarity titlesOnlyArray
  resultsArray = resultsArray.filter((result) => {
    return titlesOnlyArray.includes(result.title);
  });

  return resultsArray;
};

const getDomainKeywords = async (
  signal,
  domains,
  includeTerms,
  resultNumber,
  targetCountry = targetCountry
) => {
  if (!signal) Promise.reject("No signal provided");
  if (!domains) Promise.reject("No domains provided");

  const domainKeywords = [];

  // loop through each of the domains
  while (domains.length) {
    let domain = domains.pop();

    try {
      // fetch most valuable keywords for the domain from SpyFu API
      const keywords = await SpyFuAPI.getMostValuableKeywords(
        signal,
        domain,
        1,
        resultNumber,
        targetCountry,
        SpyFuAPI.SORT_BY.SearchVolume,
        SpyFuAPI.SORT_ORDER.Descending,
        false,
        includeTerms
      );

      if (keywords) {
        // loop through each of the results and add keyword to domainKeywords array
        keywords.results.forEach((keywordObj) => {
          domainKeywords.push(keywordObj.keyword);
        });
      }
    } catch (e) {
      domainKeywords.push(domain);
    }
  } // end while loop

  return domainKeywords;
};

const getDomainIdeas = async (
  signal,
  domain,
  topic,
  resultNumber = 10,
  headlineStyle = "SEO headline",
  targetCountry = targetCountry,
  targetLanguage = "en",
  uniqueThreshold = 0.75,
  reset = false
) => {
  const languageName = StringUtils.getLanguageCodeName(targetLanguage);

  const systemObject = {
    role: "system",
    content: `You are a research assistant that specializes in finding articles based on what other websites write about. You provide a unique title to what has been provided before and two related short-tail SEO keywords. You return the data as an array of JSON objects in this format: [{id: index, title: headline, keywords: [{label: keyword, volume: 0}, ...], countryCode: ${targetCountry}}]`,
  };

  if (reset || chatHistory.length === 0) resetIdeaHistory(systemObject);

  const returnResultSchema = [
    {
      name: "process_ideas",
      description: `A function to process an array of idea objects`,
      parameters: {
        type: "object",
        properties: {
          data: {
            type: "array",
            description: `An array of ${resultNumber} objects`,
            items: {
              type: "object",
              properties: {
                id: {
                  type: "integer",
                  description: "The index of the idea",
                },
                title: {
                  type: "string",
                  description: `The title as a ${headlineStyle}`,
                },
                keywords: {
                  type: "array",
                  description: "An array of 5 summaries",
                  items: {
                    type: "object",
                    description: "A summary object",
                    properties: {
                      label: {
                        type: "string",
                        description: "The summary",
                      },
                      volume: {
                        type: "integer",
                        description: "The volume of searches for the summary",
                      },
                    },
                  },
                },
                countryCode: {
                  type: "string",
                  description: "The country the idea is targeting",
                },
              },
              required: ["id", "title", "keywords", "countryCode"],
            },
          },
        },
      },
    },
  ];

  try {
    let userPrompt = {
      role: "user",
      content: `Find ${resultNumber} unique article ideas about ${topic} using what is written about on ${domain} as the foundation of the article ideas. Provide a headline written as a ${headlineStyle} in ${languageName} that targets a different aspect of ${topic} to what you have already provided. Do not reference the ${domain} name or brand in the new headline. Return the data as an array of JSON objects in this format: [{id: index, title: Title as a ${headlineStyle}, keywords: [{label: keyword1, volume: 0}, {label: keyword2, volume: 0}], countryCode: ${targetCountry}}]. Domain: ${domain} Topic: ${topic}.`,
    };

    chatHistory.push(userPrompt);

    let response = await GPT3.generateChat(
      signal,
      chatHistory,
      0.7,
      2048,
      returnResultSchema,
      0.6,
      0,
      GPT3.availableEngines.GPT3_LARGE
    );

    if (response) {
      if (response.choices.length > 0) {
        let resultsArray = [];

        switch (response.choices[0].finish_reason) {
          case "stop":
            resultsArray =
              JSON.parse(JSON.stringify(response.choices[0].message.content))
                .ideas || [];
            break;
          case "function_call":
            resultsArray =
              JSON.parse(response.choices[0].message.function_call.arguments)
                .ideas || [];
            break;
          default:
            console.log("No results found");
            break;
        }

        if (resultsArray.length) {
          // replace any references to old years with the current year
          resultsArray.forEach((result) => {
            result.title = StringUtils.replaceNonCurrentYears(result.title);
          });

          resultsArray = await _removeSimilarResults(
            resultsArray,
            0.75 + (0.75 - uniqueThreshold)
          );

          chatHistory.push({
            role: "assistant",
            content: resultsArray.toString(),
          });
        }

        return typeof resultsArray === "string"
          ? JSON.parse(resultsArray)
          : resultsArray;
      }
    }

    return [];
  } catch (err) {
    if (err.status === 402) publish(ToastEvents.ERROR, err.message);
    return [];
  }
};

export { getKeywordIdeas, getDomainIdeas, getDomainKeywords, resetIdeaHistory };

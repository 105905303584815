import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


const HitlistModal = ({open, onClose, title, children, footer})  => {

    return (
        <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          {title && (
            <Typography id="modal-title" variant="h6" component="h2">
              {title}
            </Typography>
          )}
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {children}
          </Typography>
          {footer && (
            <Box sx={{ mt: 3,  display: 'flex', justifyContent: 'flex-end' }}>
              {footer}
            </Box>
          )}
        </Box>
      </Modal>
    )
}

export default HitlistModal;
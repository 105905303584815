import { memo, useRef, useState, useContext, useEffect } from "react";
import { Box, Stack, Button } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SettingsHeading from "./SettingsHeading";
import { AppConfigContext } from "../AppConfigContext";
import SettingsDropdown from "./SettingsDropdown";
import SettingsSlider from "./SettingsSlider";
import Typography from "@mui/material/Typography";

function AppSettings(props) {
  const { theme, handleSaveSettings, heading, settings } = props;

  const config = useContext(AppConfigContext);

  const [sortBy, setSortBy] = useState(
    settings.sortBy ||
      config.hitlist.generate.sortBy ||
      config.settings.SORT_BY_OPTIONS[0]
  );
  const [sortOrder, setSortOrder] = useState(
    settings.sortOrder ||
      config.hitlist.generate.sortOrder ||
      config.settings.SORT_ORDER_OPTIONS[0]
  );
  const [targetLanguage, setTargetLanguage] = useState(
    settings.targetLanguage ||
      config.hitlist.generate.targetLanguage ||
      config.settings.TARGET_LANGUAGES[0]
  );

  const maxKeywordResultsRef = useRef(settings.maxKeywordResults);
  const maxDomainResultsRef = useRef(settings.maxDomainResults);
  const maxKeywordRef = useRef(settings.maxKeywords);
  const maxDomainsRef = useRef(settings.maxDomains);
  const similarFilterRef = useRef(settings.similarFilter);

  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleTargetLanguageChange = (e) => {
    setTargetLanguage(e.target.value);
  };

  const handleKeywordSliderChange = (value) => {
    maxKeywordRef.current = value;
  };

  const handleDomainSliderChange = (value) => {
    maxDomainsRef.current = value;
  };

  const handleKeywordMaxSliderChange = (value) => {
    maxKeywordResultsRef.current = value;
  };

  const handleDomainMaxSliderChange = (value) => {
    maxDomainResultsRef.current = value;
  };

  const handleSimilarSliderChange = (value) => {
    console.log("🚀 ~ file: AppSettings.js:65 ~ handleSimilarSliderChange ~ value:", value)
    similarFilterRef.current = value;
  };

  return (
    <>
    <Box
      id="application-settings"
      sx={{
        backgroundColor: theme.palette.background.main,
        border: `1px solid ${theme.palette.divider}`,
        px: 2,
        pt: 2.5,
        pb: 3,
        mb: 2,
      }}
    >
      <SettingsHeading heading={heading} />
      <Stack direction="column" sx={{ width: "80%" }}>
        <Stack direction="row" alignItems="center" spacing={6}>
          <SettingsSlider
            label={"Research Keywords"}
            value={
              settings.maxKeywords ||
              config.hitlist.generate.defaultKeywordsGenerated
            }
            help={`The number of related keywords to find at time (default: ${config.hitlist.generate.defaultKeywordsGenerated})`}
            inputRef={maxKeywordRef}
            sx={{ width: "50%" }}
            min={1}
            max={config.hitlist.generate.maxKeywordsGenerated}
            step={1}
            marks
            onChange={handleKeywordSliderChange}
            valueLabelDisplay="auto"
          />
          <SettingsSlider
            label={"Research Domains"}
            value={
              settings.maxDomains ||
              config.hitlist.generate.defaultDomainsGenerated
            }
            help={`The number of competing domains to find at a time (default: ${config.hitlist.generate.defaultDomainsGenerated})`}
            inputRef={maxDomainsRef}
            sx={{ width: "50%" }}
            min={1}
            max={config.hitlist.generate.maxDomainsGenerated}
            step={1}
            marks
            onChange={handleDomainSliderChange}
            valueLabelDisplay="auto"
          />
        </Stack>

        <Stack direction="row" alignItems="center" spacing={6} sx={{ mt: 4 }}>
          <SettingsSlider
            label={"Keyword Article Ideas"}
            value={
              settings.maxKeywordResults ||
              config.hitlist.generate.defaultKeywordResults
            }
            help={<>The number of ideas to find per keyword <Typography sx={{color: theme.palette.error.main}} component="span">*</Typography>  (default: {config.hitlist.generate.defaultKeywordResults})</>}
            inputRef={maxKeywordResultsRef}
            sx={{ width: "50%" }}
            min={1}
            max={config.hitlist.generate.maxKeywordResults}
            step={1}
            marks
            onChange={handleKeywordMaxSliderChange}
            valueLabelDisplay="auto"
          />
          <SettingsSlider
            label={"Domain Article Ideas"}
            value={
              settings.maxDomainResults ||
              config.hitlist.generate.defaultDomainResults
            }
            help={<>
              The number of articles to research per domain <Typography sx={{color: theme.palette.error.main}} component="span">*</Typography> 
              (default: {config.hitlist.generate.defaultDomainResults})
            </>}
            inputRef={maxDomainResultsRef}
            sx={{ width: "50%" }}
            min={1}
            marks
            max={config.hitlist.generate.maxDomainResults}
            step={1}
            onChange={handleDomainMaxSliderChange}
            valueLabelDisplay="auto"
          />
        </Stack>

        <Stack direction="row" alignItems="center" spacing={6} sx={{ mt: 4 }}>
          <SettingsDropdown
            label="Sort Results By"
            sx={{ width: "50%" }}
            value={sortBy || config.hitlist.generate.sortBy}
            help={`Default: ${
              config.settings.SORT_BY_OPTIONS.find(
                (option) => option.value === config.hitlist.generate.sortBy
              ).label
            }`}
            onChange={handleSortByChange}
            data={config.settings.SORT_BY_OPTIONS}
          />
          <SettingsDropdown
            label="Results Sort Order"
            sx={{ width: "50%" }}
            value={sortOrder || config.hitlist.generate.sortOrder}
            help={`Default: ${
              config.settings.SORT_ORDER_OPTIONS.find(
                (option) => option.value === config.hitlist.generate.sortOrder
              ).label
            }`}
            onChange={handleSortOrderChange}
            data={config.settings.SORT_ORDER_OPTIONS}
          />
        </Stack>

        <Stack direction="row" alignItems="center" spacing={6} sx={{ mt: 4 }}>
          <SettingsDropdown
            label="Article Language"
            sx={{ width: "50%" }}
            value={targetLanguage || config.hitlist.generate.targetLanguage}
            help={`The language your site is written in. (default: ${
              config.settings.TARGET_LANGUAGES.find(
                (option) =>
                  option.value === config.hitlist.generate.targetLanguage
              ).label
            })`}
            onChange={handleTargetLanguageChange}
            data={config.settings.TARGET_LANGUAGES}
          />
          <SettingsSlider
            label={"Unique Content"}
            value={
              settings.similarFilter ||
              config.hitlist.generate.defaultSimilarFilter
            }
            help={`How strictly to look for unique results. The closer to Max, the more unique the results, but the less results are returned.`}
            inputRef={similarFilterRef}
            sx={{ width: "50%" }}
            min={0}
            max={0.5}
            step={0.05}
            onChange={handleSimilarSliderChange}
            marks={[
              {
                value: 0,
                label: "None",
              },
              {value: 0.25, label: "Default"},
              {
                value: 0.5,
                label: "Max",
              },
            ]}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={6}
          sx={{ mt: 4 }}
        > 
       
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() =>
              handleSaveSettings({
                maxDomainResults: maxDomainResultsRef.current,
                maxDomains: maxDomainsRef.current,
                maxKeywordResults: maxKeywordResultsRef.current,
                maxKeywords: maxKeywordRef.current,
                similarFilter: similarFilterRef.current,
                sortBy: sortBy,
                sortOrder: sortOrder,
                targetLanguage: targetLanguage,
              })
            }
            startIcon={<SaveOutlinedIcon />}
          >
            Save Settings
          </Button>
        </Stack>
      </Stack>
    </Box>
    <Typography sx={{ fontSize: 12, color: theme.palette.text.label, mt: 0.5, mb: 2 }}>
  <Typography component="span" sx={{ color: theme.palette.error.main }}>* </Typography>
   Maximum ideas per keyword (actual count may vary due to duplicates)
</Typography>
     </>
  );
}

export default memo(AppSettings);

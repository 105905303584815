import { memo } from "react";
import { Tooltip, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";

function HitlistTableLink(props) {
  const { value, row } = props;
  const encodedTitle = encodeURIComponent(row.title.toLowerCase());

  const theme = useTheme();

  return (
    <Tooltip
      title="Open top result in new tab"
      arrow
      enterDelay={300}
      enterNextDelay={600}
    >
      <span>
          <Link href={`https://google.com/search?q=${encodedTitle}`} target="_blank" rel="noreferrer">
            <TravelExploreOutlinedIcon
              fontSize="10px"
              sx={{
                mt: 1,
                color: theme.palette.text.primary,
              }}
            />
          </Link>
      </span>
    </Tooltip>
  );
}

export default memo(HitlistTableLink);

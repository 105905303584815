import { memo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

function ResetDialog(props) {
  const { open, handleResetClose, resetAllData } = props;
  const theme = useTheme();

  const closeDialogAndReset = () => {
    handleResetClose();
    resetAllData();
  };

  return (
    <Dialog
      open={open}
      onClose={handleResetClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Reset all data?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Are you sure you want to reset all data including your topic, research, and all hitlist data? Note that this action will also remove all data from your final hitlist.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleResetClose}
          sx={{ width: 80, color: theme.palette.neutral.main }}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          onClick={closeDialogAndReset}
          autoFocus
          sx={{ width: 80, color: theme.palette.neutral.main }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ResetDialog);

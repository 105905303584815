import axios from "axios";
import axiosRetry from "axios-retry";
import { CustomEvents, publish } from "../../Hitlist/Events/CustomEvents";
import { STORAGE_KEYS, getValue } from "../../Storage";
import { throwError } from "../../ErrorHandler/ErrorHandler";
import Userfront from "@userfront/core";
import { sort } from "semver";

const apiKey = process.env.REACT_APP_SPYFU_KEY;
const minCredit = 1;

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  baseURL: process.env.REACT_APP_SERVER_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = Userfront.tokens.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const retryCondition = (error) => {
  console.error("🚀 ~ file: OpenAiApi.js:34 ~ error:", error);
  return (
    axiosRetry.isNetworkError(error) ||
    axiosRetry.isRetryableError(error) ||
    error.code === "ECONNABORTED" ||
    error.response.status === 429 ||
    error.response.status === 500
  );
};

axiosRetry(api, {
  retries: 5,
  retryCondition: retryCondition,
  shouldResetTimeout: true,
});

const getCredits = async () => {
  return getValue(STORAGE_KEYS.CREDITS) || null;
};

const logTokens = (response) => {
  console.log("🚀 ~ file: SpyFuApi.js:238 ~ response:", response);

  const tokenFactor = parseInt(process.env.REACT_APP_SPYFU_MULTIPLE); // how much more expensive than the baseline of GPT3.5

  if (!response) return;
  if (!response.data) return;
  if (!response.data.results) return;

  if (response.data.results.length > 0) {
    console.log("RAW TOKENS: ", response.data.resultCount);

    publish(CustomEvents.TOKENS_USED, {
      date: new Date(),
      model: "spyfu-v2",
      tokens: {
        prompt_tokens: 0,
        completion_tokens: response.data.resultCount * tokenFactor,
        total_tokens: response.data.resultCount * tokenFactor,
      },
    });
  }
};

const SpyFuAPI = {
  COUNTRY_CODES: {
    AU: "AU",
    BR: "BR",
    CA: "CA",
    DE: "DE",
    ES: "ES",
    FR: "FR",
    GB: "UK",
    IE: "IE",
    IN: "IN",
    IT: "IT",
    MX: "MX",
    NL: "NL",
    SG: "SG",
    UK: "UK",
    US: "US",
  },
  SORT_BY: {
    SearchVolume: "SearchVolume",
    RankingDifficulty: "RankingDifficulty",
    Rank: "Rank",
    RankChange: "RankChange",
    SeoClicks: "SeoClicks",
    SeoClicksChange: "SeoClicksChange",
    PercentMobileSearches: "PercentMobileSearches",
    PercentDesktopSearches: "PercentDesktopSearches",
    PercentNotClicked: "PercentNotClicked",
    PercentPaidClicks: "PercentPaidClicks",
    PercentOrganicClicks: "PercentOrganicClicks",
    BroadCostPerClick: "BroadCostPerClick",
    ExactCostPerClick: "ExactCostPerClick",
    PhraseCostPerClick: "PhraseCostPerClick",
    BroadMonthlyCost: "BroadMonthlyCost",
    ExactMonthlyCost: "ExactMonthlyCost",
    PhraseMonthlyCost: "PhraseMonthlyCost",
    TotalMonthlyClicks: "TotalMonthlyClicks",
    PaidCompetitors: "PaidCompetitors",
    RankingHomepages: "RankingHomepages",
  },
  SORT_ORDER: {
    Ascending: "Ascending",
    Descending: "Descending",
  },
  /**
   * Method to get the keywords that their clicks provide the most value to a given domain
   * Use try catch when calling this method to catch errors
   * @method getMostValuableKeywords
   * @param {String} url
   * @param {Int} pageSize
   * @param {String} country Any of: AU | BR | CA | DE | ES | FR | IE | IN | IT | MX | NL | SG | UK | US
   * @param {String} sortBy Any of: SearchVolume | RankingDifficulty | Rank | RankChange | SeoClicks | SeoClicksChange | PercentMobileSearches | PercentDesktopSearches | PercentNotClicked | PercentPaidClicks | PercentOrganicClicks | BroadCostPerClick | ExactCostPerClick | PhraseCostPerClick | BroadMonthlyCost | ExactMonthlyCost | PhraseMonthlyCost | TotalMonthlyClicks | PaidCompetitors | RankingHomepages
   * @param {String} sortOrder Any of: Ascending | Descending
   * @param {Boolean} adultFilter
   * @returns {Object} response.data
   */
  getMostValuableKeywords: async (
    signal,
    url,
    pageNumber = 1,
    pageSize = 10,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US,
    sortBy = SpyFuAPI.SORT_BY.SearchVolume,
    sortOrder = SpyFuAPI.SORT_ORDER.Descending,
    adultFilter = false,
    includeTerms = ""
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry = SpyFuAPI.COUNTRY_CODES[targetCountry] || targetCountry;

      if (availableCredits >= minCredit) {
        try {
          const response = await api.get(`/keywords/valuable`, {
            params: {
              url: url,
              countryCode: targetCountry,
              sortBy: sortBy,
              sortOrder: sortOrder,
              pageSize: pageSize,
              pageNumber: pageNumber,
              adultFilter: adultFilter,
              includeTerms: includeTerms,
              includeAnyTerm: true,
              excludeHomepageKeywords: true,
            }
          }); 

          console.log("🚀 ~ file: SpyFuApi.js:108 ~ response:", response);

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
      throw e;
    }
  },
  /**
   * Method to get top SEO competitors of a given domain
   * Use try catch when calling this method to catch errors
   * @param {String} url
   * @param {Int} pageSize
   * @param {String} country
   * @returns {Object}
   */
  getTopSEOCompetitors: async (
    signal,
    url,
    pageNumber = 1,
    pageSize = 10,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry =
        SpyFuAPI.COUNTRY_CODES[targetCountry.toUpperCase()] ||
        SpyFuAPI.COUNTRY_CODES.US;

      if (availableCredits >= minCredit) {
        try {

          const response = await api.get(`/keywords/competitors`, {
            params: {
              domain: url,
              startingRow: pageSize * (pageNumber - 1) + 1,
              pageSize: pageSize,
              countryCode: targetCountry,
            }
          });
          console.log("🚀 ~ file: SpyFuApi.js:145 ~ response:", response);

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:221 ~ e", e);
      throw e;
    }
  },

  /**
   * Method to retrieve questions for a keyword
   * Use try catch when calling this method to catch errors
   * @method getQuestionKeywords
   * @param {String} keyword
   * @param {Int} pageSize
   * @param {String} country
   * @param {Boolean} adultFilter
   * @returns {Object} response.data
   */
  getQuestionKeywords: async (
    signal,
    keyword,
    pageNumber = 1,
    pageSize = 10,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US,
    sortBy = SpyFuAPI.SORT_BY.SearchVolume,
    sortOrder = SpyFuAPI.SORT_ORDER.Descending,
    adultFilter = false
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry = SpyFuAPI.COUNTRY_CODES[targetCountry];

      if (availableCredits >= minCredit) {
        try {

          const response = await api.get(`/keywords/questions`, {
            params: {
              keyword: keyword,
              sortBy: sortBy,
              sortOrder: sortOrder,
              startingRow: pageSize * (pageNumber - 1) + 1,
              pageSize: pageSize,
              targetCountry: targetCountry,
              adultFilter: adultFilter,
            }
          });

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:436 ~ e", e);
      throw e;
    }
  },
  
  getTransactionKeywords: async (
    signal,
    keyword,
    pageNumber = 1,
    pageSize = 10,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US,
    sortBy = SpyFuAPI.SORT_BY.SearchVolume,
    sortOrder = SpyFuAPI.SORT_ORDER.Descending,
    adultFilter = false
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();

      // convert the targetCountry code to the one used by SpyFu
      targetCountry = SpyFuAPI.COUNTRY_CODES[targetCountry];

      if (availableCredits >= minCredit) {
        try {

          const response = await api.get(`/keywords/transactions`, {
            params: {
              keyword: keyword,
              sortBy: sortBy,
              sortOrder: sortOrder,
              startingRow: pageSize * (pageNumber - 1) + 1,
              pageSize: pageSize,
              targetCountry: targetCountry,
              adultFilter: adultFilter,
            }
          });

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:113 ~ e", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:542 ~ e", e);
      throw e;
    }
  },

  getKeywordInformation: async (
    signal,
    keyword,
    targetCountry = SpyFuAPI.COUNTRY_CODES.US
  ) => {
    if (!signal) Promise.reject("No signal provided");

    try {
      const availableCredits = await getCredits();
      // convert the targetCountry code to the one used by SpyFu
      targetCountry = SpyFuAPI.COUNTRY_CODES[targetCountry];
      if (availableCredits >= minCredit) {
        try {
          
          const response = await api.get(`/keywords/related`, {
            params: {
              keywords: keyword.join(", "),
              countryCode: targetCountry,
            }
          });

          logTokens(response);

          // add the country code to the data before returning it
          response.data.countryCode = targetCountry;

          return response.data;
        } catch (e) {
          console.log("🚀 ~ file: SpyFuApi.js:513 ~ e:", e);
          throw e;
        }
      } else {
        throwError(
          `Insufficient credits. Your current balance is ${availableCredits}`,
          402
        );
      }
    } catch (e) {
      console.log("🚀 ~ file: SpyFuApi.js:584 ~ e", e);
      throw e;
    }
  },
};

export default SpyFuAPI;
